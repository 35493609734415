import React from "react";

export const MyAccount = () => {
    return (
        
            <div className="text-4xl text-cyan-500">
                My Account Page
            </div>
        
    )
}