import React from "react";


export const MyRequests = () => {
    return (
        
            <div className="text-4xl text-cyan-500">
                My Requests Page
            </div>
        
    )
}