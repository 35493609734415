import React from "react";


export const MyAnalytics = () => {
    return (
        
            <div className="text-4xl text-cyan-500">
                My Analytics Page
            </div>
        
    )
}